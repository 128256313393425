import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  overflow: hidden;
  transform: translateZ(0);
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 100%;
  }

  @media (max-width: 991px) {
    height: 200px;
  }
`

const Background = styled.div`
  background-color: #1c1c1c;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;

  @media (min-width: 576px) {
    top: 50%;
  }

  @media (max-width: 575px) {
    top: calc(200px + 3rem);
  }
`

const Container = styled.div`
  position: relative;
  z-index: 2;
`

const Content = styled(ParseContent)`
  & h4 {
    color: ${({ theme }) => theme.color.dark};
    font-size: ${({ theme }) => theme.font.size.large};
    letter-spacing: 1.6px;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    margin-bottom: 1rem;
  }

  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.mega};
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
`

const StyledFooterText = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

interface FooterTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_FooterText
}

const FooterText: React.FC<FooterTextProps> = ({ fields }) => (
  <StyledFooterText className="position-relative">
    <Background />
    <Container className="container">
      <div className="row justify-content-center mx-0 pt-lg-5">
        <div className="col-12 col-lg-11 col-xl-9">
          <Block className="row">
            <div className="col-md-6 px-0">
              <Image image={fields.image} alt="VT Tuinen" />
            </div>
            <div className="col-md-6 py-4 px-3 px-lg-5 d-flex align-items-center">
              <div>
                <Content content={fields.description} />
              </div>
            </div>
          </Block>
        </div>
      </div>
    </Container>
  </StyledFooterText>
)

export default FooterText
